// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-charlie-pizza-js": () => import("./../src/pages/charlie-pizza.js" /* webpackChunkName: "component---src-pages-charlie-pizza-js" */),
  "component---src-pages-cheatday-js": () => import("./../src/pages/cheatday.js" /* webpackChunkName: "component---src-pages-cheatday-js" */),
  "component---src-pages-logos-marks-js": () => import("./../src/pages/logos-marks.js" /* webpackChunkName: "component---src-pages-logos-marks-js" */),
  "component---src-pages-magazines-prints-js": () => import("./../src/pages/magazines-prints.js" /* webpackChunkName: "component---src-pages-magazines-prints-js" */),
  "component---src-pages-molas-js": () => import("./../src/pages/molas.js" /* webpackChunkName: "component---src-pages-molas-js" */),
  "component---src-pages-prie-vilneles-js": () => import("./../src/pages/prie-vilneles.js" /* webpackChunkName: "component---src-pages-prie-vilneles-js" */),
  "component---src-pages-spotiself-js": () => import("./../src/pages/spotiself.js" /* webpackChunkName: "component---src-pages-spotiself-js" */),
  "component---src-pages-vici-abc-js": () => import("./../src/pages/vici-abc.js" /* webpackChunkName: "component---src-pages-vici-abc-js" */),
  "component---src-templates-portfolio-template-js": () => import("./../src/templates/portfolio-template.js" /* webpackChunkName: "component---src-templates-portfolio-template-js" */)
}

